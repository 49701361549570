import { css } from '@emotion/react'
import type { CpmsRequestResult } from '@orus.eu/backend/src/routers/cpms'
import type { CalendarDate } from '@orus.eu/calendar-date'
import {
  Button,
  ContentContainerBackoffice,
  DatepickerFormField,
  LegacyDialog,
  Text,
  TextFieldFormField,
  jsonTreeTheme,
  spacing,
  useAsyncCallback,
  useDialogVisibility,
  type LegacyDialogStyle,
} from '@orus.eu/pharaoh'
import { Header } from '@orus.eu/pharaoh/src/patterns/header'
import { isSuccess } from '@orus.eu/result'
import { memo, useCallback, useMemo, useState, type ChangeEvent } from 'react'
import { JSONTree } from 'react-json-tree'
import { trpc } from '../../../../client'
import { useNavigateTo } from '../../../../lib/hooks/use-navigate-to-route'
import { CopyToClipboardButton } from '../../../molecules/copy-to-clipboard-button'

type CpmsRequestResultDialogProps = {
  result: CpmsRequestResult
  onClose: () => void
}

export default function BackofficeAdminCpmsPage(): JSX.Element {
  const [subscriptionId, setSubscriptionId] = useState<string | undefined>(undefined)
  const [signatureId, setSignatureId] = useState<string | undefined>(undefined)
  const [startEffectDate, setStartEffectDate] = useState<CalendarDate | undefined>(undefined)
  const [result, setResult] = useState<CpmsRequestResult | undefined>(undefined)
  const [isCreateLoading, setIsCreateLoading] = useState(false)
  const [isUpdateLoading, setIsUpdateLoading] = useState(false)
  const [isTerminateLoading, setIsTerminateLoading] = useState(false)

  const { show: showDialog, hide: hideDialog, visible: isDialogVisible } = useDialogVisibility('cpms-request')

  const navigateToBackofficeAdmin = useNavigateTo({ to: '/bak/admin' })

  const onSetSubscriptionId = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSubscriptionId(event.target.value)
  }, [])

  const onSetSignatureId = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSignatureId(event.target.value)
  }, [])

  const onSetStartEffectDate = useCallback((newValue: CalendarDate | null) => {
    setStartEffectDate(newValue ?? undefined)
  }, [])

  const onClickCreateAdhesion = useAsyncCallback(async () => {
    if (subscriptionId === undefined || subscriptionId === '') {
      alert('Subscription ID is required')
      return
    }
    setIsCreateLoading(true)
    setResult(await trpc.cpms.createAdhesion.mutate({ subscriptionId, signatureId, startEffectDate }))
    setIsCreateLoading(false)
    showDialog()
  }, [showDialog, signatureId, subscriptionId, startEffectDate])

  const onClickUpdateAdhesion = useAsyncCallback(async () => {
    if (subscriptionId === undefined || subscriptionId === '') {
      alert('Subscription ID is required')
      return
    }
    setIsUpdateLoading(true)
    setResult(await trpc.cpms.updateAdhesion.mutate({ subscriptionId, signatureId, startEffectDate }))
    setIsUpdateLoading(false)
    showDialog()
  }, [showDialog, signatureId, subscriptionId, startEffectDate])

  const onClickTerminateAdhesion = useAsyncCallback(async () => {
    if (subscriptionId === undefined || subscriptionId === '') {
      alert('Subscription ID is required')
      return
    }

    setIsTerminateLoading(true)
    setResult(await trpc.cpms.terminateAdhesion.mutate(subscriptionId))
    showDialog()
    setIsTerminateLoading(false)
  }, [showDialog, subscriptionId])

  return (
    <ContentContainerBackoffice marginTop={spacing[70]}>
      <Header
        title="CPMS Admin Page"
        leftButton={
          <Button icon="arrow-left-regular" size="small" variant="secondary" onClick={navigateToBackofficeAdmin} />
        }
      />
      <div
        css={css`
          margin-top: ${spacing[50]};
          display: flex;
          flex-direction: column;
          gap: ${spacing[90]};
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: ${spacing[70]};
          `}
        >
          <Text>
            Please remember, if you use this you need to check the logs in database or Slack channel (#notifs-cpms) to
            know the reasons of success or failure of your request.
          </Text>
          <TextFieldFormField
            size="small"
            label="Subscription ID"
            value={subscriptionId}
            onChange={onSetSubscriptionId}
          />{' '}
          <TextFieldFormField
            size="small"
            label="Signature ID"
            helperText="If not provided, we will use the first signature of the contract for creation and last signature for an endorsement. Contract termination does not care about this variable."
            value={signatureId}
            onChange={onSetSignatureId}
          />
          <DatepickerFormField
            label={'Start effect date'}
            onChange={onSetStartEffectDate}
            value={startEffectDate}
            size="small"
          />
        </div>
        <div
          css={css`
            display: flex;
            gap: ${spacing[40]};
          `}
        >
          <Button variant="primary" size="small" onClick={onClickCreateAdhesion} isLoading={isCreateLoading}>
            Créer une adhésion
          </Button>
          <Button variant="primary" size="small" onClick={onClickUpdateAdhesion} isLoading={isUpdateLoading}>
            Mettre à jour une adhésion (avenant)
          </Button>
          <Button variant="primary" size="small" onClick={onClickTerminateAdhesion} isLoading={isTerminateLoading}>
            Résilier une adhésion
          </Button>
        </div>
      </div>
      {isDialogVisible && result ? <CpmsRequestResultDialog result={result} onClose={hideDialog} /> : <></>}
    </ContentContainerBackoffice>
  )
}

const CpmsRequestResultDialog = memo<CpmsRequestResultDialogProps>(function CpmsRequestResultDialog({
  result,
  onClose,
}) {
  const isRequestSuccess = useMemo(() => {
    return isSuccess(result)
  }, [result])

  const style: LegacyDialogStyle = useMemo(() => {
    if (isRequestSuccess) return 'normal'
    else return 'problem'
  }, [isRequestSuccess])

  const title = useMemo(() => {
    if (isRequestSuccess) return 'Requête réussie'
    else return 'Requête en erreur'
  }, [isRequestSuccess])

  return (
    <LegacyDialog
      variant="backoffice"
      fullWidth
      style={style}
      title={title}
      onClose={onClose}
      onSecondaryAction={onClose}
    >
      {isSuccess(result) ? (
        <Text>Tout s&apos;est bien déroulé !</Text>
      ) : (
        <div>
          <CopyToClipboardButton
            stringToCopy={JSON.stringify(result.problem)}
            variant="secondary"
            icon="copy-regular"
            avatarPosition="left"
            css={css`
              margin-top: ${spacing[40]};
            `}
          >
            Copier les données
          </CopyToClipboardButton>
          <JSONTree data={result.problem} theme={jsonTreeTheme} hideRoot />
        </div>
      )}
    </LegacyDialog>
  )
})
